var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('OperatingHourModal', {
    attrs: {
      "visibility": _vm.modal.visibility,
      "loading": _vm.modal.loading
    },
    on: {
      "update:loading": function updateLoading($event) {
        return _vm.$set(_vm.modal, "loading", $event);
      },
      "click:submit": _vm.getDataFromApi,
      "click:cancel": function clickCancel($event) {
        return _vm.openOperationalHourModal(false);
      },
      "updateVisibility": function updateVisibility($event) {
        return _vm.openOperationalHourModal(false);
      }
    }
  }), _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold mb-0"
  }, [_vm._v("月稼働時間管理")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.operationalHours,
      "total-records": _vm.totalRecords,
      "number-of-pages": _vm.numberOfPages,
      "items-per-page": _vm.itemsPerPage,
      "footer": false,
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mt-2 mb-4"
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "btn-new-task",
          attrs: {
            "height": "27",
            "color": "#CFD3FE",
            "depressed": "",
            "min-width": "100"
          },
          on: {
            "click": function click($event) {
              return _vm.openOperationalHourModal(true);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": "",
            "size": "16"
          }
        }, [_vm._v(" mdi-plus ")]), _vm._v(" 新しい月を追加 ")], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.operational_hours",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('v-text-field', {
          staticClass: "mt-0",
          attrs: {
            "label": "労働時間",
            "solo": "",
            "flat": "",
            "dense": "",
            "hide-details": "",
            "maxlength": "3",
            "min": "1"
          },
          on: {
            "input": function input($event) {
              return _vm.updateOperationalHourEntry(item);
            },
            "keypress": function keypress($event) {
              return _vm.limitDigits($event);
            }
          },
          model: {
            value: item.operational_hours,
            callback: function callback($$v) {
              _vm.$set(item, "operational_hours", _vm._n($$v));
            },
            expression: "item.operational_hours"
          }
        }), _c('div', {
          staticClass: "ml-2 font-weight-bold"
        }, [_vm._v("時間")])], 1)];
      }
    }, {
      key: "item.comments",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('v-textarea', {
          staticClass: "mt-0",
          attrs: {
            "label": "今月の目標",
            "solo": "",
            "flat": "",
            "dense": "",
            "hide-details": "",
            "rows": "2",
            "row-height": "20"
          },
          on: {
            "input": function input($event) {
              return _vm.updateOperationalHourEntry(item);
            },
            "keypress": function keypress($event) {
              return _vm.disableTemporarily(item, $event);
            }
          },
          model: {
            value: item.comments,
            callback: function callback($$v) {
              _vm.$set(item, "comments", $$v);
            },
            expression: "item.comments"
          }
        })], 1)];
      }
    }, {
      key: "item.updating",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-progress-circular', {
          staticClass: "ml-2",
          style: "visibility: ".concat(item.updating ? 'visible' : 'hidden'),
          attrs: {
            "size": 25,
            "width": 3,
            "indeterminate": true,
            "color": "primary"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "color": "red"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteTask(item.id);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("$trash")])], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }