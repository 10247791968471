<template>
  <div>
    <OperatingHourModal :visibility="modal.visibility" :loading.sync="modal.loading" @click:submit="getDataFromApi"
      @click:cancel="openOperationalHourModal(false)" @updateVisibility="openOperationalHourModal(false)" />
    <div class="pa-9 pa-md-6 pa-lg-9">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <h3 class="page-title font-weight-bold mb-0">月稼働時間管理</h3>
          </v-col>

          <v-col cols="12">
            <Table :headers="headers" :items="operationalHours" :total-records="totalRecords"
              :number-of-pages="numberOfPages" :items-per-page="itemsPerPage" :footer="false" :loading="loading">
              <template v-slot:top>
                <div class="mt-2 mb-4">
                  <v-row align="center">
                    <v-spacer></v-spacer>

                    <v-col cols="auto">
                      <v-btn height="27" class="btn-new-task" color="#CFD3FE" depressed min-width="100"
                        @click="openOperationalHourModal(true)">
                        <v-icon left size="16">
                          mdi-plus
                        </v-icon>
                        新しい月を追加
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </template>

              <template v-slot:item.operational_hours="{ item }">
                <div class="d-flex align-center">
                  <v-text-field v-model.number="item.operational_hours" label="労働時間" solo flat dense hide-details
                    class="mt-0" @input="updateOperationalHourEntry(item)" maxlength="3" @keypress="limitDigits($event)"
                    min="1">
                  </v-text-field>
                  <div class="ml-2 font-weight-bold">時間</div>
                </div>
              </template>

              <template v-slot:item.comments="{ item }">
                <div class="d-flex align-center">
                  <v-textarea v-model="item.comments" label="今月の目標" solo flat dense hide-details class="mt-0"
                    @input="updateOperationalHourEntry(item)" @keypress="disableTemporarily(item, $event)" rows="2"
                    row-height="20">
                  </v-textarea>
                </div>
              </template>

              <template v-slot:item.updating="{ item }">
                <v-progress-circular :size="25" :width="3" :indeterminate="true" color="primary" class="ml-2"
                  :style="`visibility: ${item.updating ? 'visible' : 'hidden'}`">
                </v-progress-circular>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn text @click="deleteTask(item.id)" color="red">
                  <v-icon small>$trash</v-icon>
                </v-btn>
              </template>
            </Table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import dayjs from 'dayjs'
import axios from 'axios'
import OperatingHourModal from '@/components/dialog/OperatingHourModal'
import { mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
export default {
  name: 'List',
  components: { OperatingHourModal, Table },
  data() {
    return {
      headers: [
        {
          text: '西暦',
          align: 'center',
          value: 'year',
          width: '100',
          sortable: false
        },
        {
          text: '月',
          align: 'center',
          value: 'month',
          width: '100',
          sortable: false
        },
        {
          text: '総労働時間',
          align: 'left',
          value: 'operational_hours',
          width: '150',
          sortable: false,
          class: 'text-left'
        },
        { text: '今月の目標', align: 'left', value: 'comments', sortable: false },
        {
          text: '',
          align: 'center',
          width: '25',
          value: 'updating',
          sortable: false
        },
        {
          text: '',
          align: 'center',
          value: 'actions',
          width: 50,
          sortable: false
        }
      ],
      // operationalHours: [],
      modal: {
        visibility: false,
        loading: false
      },
      filter: null,
      loading: false,
      itemsPerPage: 25,
      numberOfPages: null,
      totalRecords: 0,
      options: {},
      workTimeId: 0
    }
  },

  computed: {
    operationalHours() {
      return this.$store.getters.operationalHours.map(item => {
        return { ...item, updating: false }
      })
    }
  },

  created() {
    this.getDataFromApi()
  },

  methods: {
    ...mapMutations(['showModal']),
    openOperationalHourModal(status) {
      this.modal.visibility = status
    },

    updateOperationalHourEntry: debounce(function (item) {
      item.updating = true

      let obj = {
        id: item.id,
        operational_hours: item.operational_hours,
        comments: item.comments
      }
      this.$store
        .dispatch('WORKTIME_OPERATIONAL_HOURS_UPDATE', obj)
        .then(response => {
          let data = response.data.data.operational_hour
          item.operational_hours = data.operational_hours
          item.comments = data.comments
          item.updating = false
        })
        .finally(() => {
          item.updating = false
        })
    }, 2000),

    getDataFromApi(filter = {}) {
      this.loading = true
      this.operationalHours = []
      filter.paginate = 25
      this.$store
        .dispatch('WORKTIME_OPERATIONAL_HOURS', filter)
        .then(res => {
          this.numberOfPages = res.data.paginate.total_pages
          this.totalRecords = res.data.paginate.records_total
        })
        .finally(() => (this.loading = false))
    },

    disableTemporarily(item, event) {
      if (item.updating) {
        event.preventDefault()
      }
    },

    limitDigits(event) {
      if (event.charCode < 48 || event.charCode > 57) event.preventDefault()
      return true
    },
    deleteTask(id) {
      this.workTimeId = id
      this.showModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },
    processDelete() {
      let data = {}
      data.id = this.workTimeId
      this.$store.dispatch('WORKTIME_OPERATIONAL_HOURS_DELETE', data).then(
        response => {
          if (response.data.status == 'success') {
            let filter = {}
            this.loading = true
            this.operationalHours = []
            filter.paginate = 25
            this.$store
              .dispatch('WORKTIME_OPERATIONAL_HOURS', filter)
              .then(res => {
                this.numberOfPages = res.data.paginate.total_pages
                this.totalRecords = res.data.paginate.records_total
              })
          }
        },
        error => {
          throw error
        }
      )
    }
  }
}
</script>

<style src="./List.scss" lang="scss" scoped>
</style>
