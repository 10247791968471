<template>
  <v-dialog max-width="600" :value="visibility" @keydown.esc="updateVasibility" @click:outside="updateVasibility">
    <validation-observer ref="observer">
      <form @submit.prevent="submit">
        <v-card>
          <v-card-text>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12" class="font-weight-bold text-center black--text">
                  新しい月を追加する
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                  <span class="mr-2">西暦</span>
                  <validation-provider v-slot="{ errors }" name="year" rules="required|min:1">
                    <v-autocomplete v-model.number="operatingHour.year" no-filter :error="errors.length !== 0"
                      :error-messages="errors" v-mask="'####'" outlined flat dense hide-details="auto" class="mt-0"
                      maxlength="4" :search-input.sync="search" :items="items" @keypress="limitDigits($event)">
                    </v-autocomplete>
                  </validation-provider>
                  <span class="ml-2">年</span>
                </v-col>

                <v-col cols="auto" class="d-flex align-center">
                  <validation-provider v-slot="{ errors }" name="month" rules="required">
                    <v-select v-model="operatingHour.month" :error="errors.length !== 0" :error-messages="errors"
                      outlined flat dense hide-details="auto" class="mt-0" :items="months"></v-select>
                  </validation-provider>

                  <span class="ml-2">月</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$emit('click:cancel')" color="red">
              <v-icon left>$trash</v-icon>
              キャンセル
            </v-btn>
            <v-btn class="white--text" color="#4F55A7" :loading="loading" type="submit">
              追加する
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </validation-observer>
  </v-dialog>
</template>

<script>
export default {
  name: 'OperatingHourModal',
  props: ['visibility', 'loading'],
  data() {
    return {
      operatingHour: {
        year: new Date().getFullYear().toString(),
        month: '',
        operational_hours: 1,
        comments: ''
      },
      search: null,
      items: [new Date().getFullYear().toString(), (new Date().getFullYear() + 1).toString(), (new Date().getFullYear() + 2).toString()],
      initialLoad: true
    }
  },
  watch: {
    search(val) {
      if (!this.initialLoad) {
        if (val?.length === 4) {
          this.items = []
          for (let i = 0; i < 3; i++) {
            const number = parseInt(val) + i
            this.items.push(number)
          }
        }
      } else {
        this.initialLoad = false
      }

    }
  },
  computed: {
    months() {
      return Array.from(Array(12).keys()).map(i => `${i + 1}`)
    },
  },
  methods: {
    submit() {
      this.$emit('update:loading', true)
      this.$refs.observer.validate().then(success => {
        if (!success) return

        let data = {
          year_month: `${this.operatingHour.year}-${this.operatingHour.month.padStart(2, '0')}-01`,
          operational_hours: this.operatingHour.operational_hours,
          comments: this.operatingHour.comments
        }
        this.$store
          .dispatch('WORKTIME_OPERATIONAL_HOURS_CREATE', data)
          .then(() => {
            this.$refs.observer.reset()
            this.$emit('click:submit', this.operatingHour)
            this.updateVasibility()
            this.operatingHour = {
              year: '',
              month: '',
              operational_hours: 0,
              comments: ''
            }
          })

          .finally(() => {
            this.$emit('update:loading', false)
          })
      })
    },
    updateVasibility() {
      this.$emit('updateVisibility', false)
    },
    limitDigits(event) {
      if (event.charCode < 48 || event.charCode > 57) event.preventDefault()
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input__slot {}
}
</style>
